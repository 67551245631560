import React from 'react'
import {Link, NavLink} from 'react-router-dom'


function Footer() {
    return (

        <footer className="footer static-bottom footer-dark footer-custom-class" data-midnight="white">
            <div className="container">
                <div className="footer-wrapper mx-auto text-center">
                    <div className="footer-title mb-5 animated" data-animation="fadeInUpShorter"
                         data-animation-delay="0.2s">Stay updated with us
                    </div>
                    <div action="#" method="post" className="subscribe mb-3 animated" data-animation="fadeInUpShorter"
                         data-animation-delay="0.3s" >
                        <input type="text" name="subscribe" className="subscribe-text"
                               placeholder="Enter your email address"/>
                            <button type="submit"
                                    className="btn btn-gradient-orange btn-glow rounded-circle subscribe-btn"><i
                                className="ti-angle-right"></i></button>
                    </div>
                    <p className="subscribe-desc mb-4 animated" data-animation="fadeInUpShorter"
                       data-animation-delay="0.4s">Subscribe now and be the first to find about our latest products!</p>
                    <ul className="social-buttons list-unstyled mb-5">
                        <li className="animated" data-animation="fadeInUpShorter" data-animation-delay="0.5s"><a
                            href="#" title="Facebook" className="btn btn-outline-facebook rounded-circle"><i
                            className="ti-facebook"></i></a></li>
                        <li className="animated" data-animation="fadeInUpShorter" data-animation-delay="0.6s"><a
                            href="#" title="Twitter" className="btn btn-outline-twitter rounded-circle"><i
                            className="ti-twitter-alt"></i></a></li>
                        <li className="animated" data-animation="fadeInUpShorter" data-animation-delay="0.7s"><a
                            href="#" title="LinkedIn" className="btn btn-outline-linkedin rounded-circle"><i
                            className="ti-linkedin"></i></a></li>
                        <li className="animated" data-animation="fadeInUpShorter" data-animation-delay="0.8s"><a
                            href="#" title="GitHub" className="btn btn-outline-github rounded-circle"><i
                            className="ti-github"></i></a></li>
                        <li className="animated" data-animation="fadeInUpShorter" data-animation-delay="0.9s"><a
                            href="#" title="Pintrest" className="btn btn-outline-pinterest rounded-circle"><i
                            className="ti-pinterest"></i></a></li>
                    </ul>
                    <span className="copyright animated" data-animation="fadeInUpShorter"
                          data-animation-delay="1.0s">Copyright &copy; 2019, Crypto ICO. Template Designed by<a
                        href="http://pixinvent.com" title="pixinvent"> Pixinvent</a></span>
                </div>
            </div>
        </footer>
)

}


export default Footer
