import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './logo.svg';
import "./app/theme-assets/css/font.css";
import "./app/theme-assets/css/bootstrap.min.css";
import "./app/theme-assets/fonts/themify/style.css";
import "./app/theme-assets/fonts/flag-icon-css/css/flag-icon.min.css";
import "./app/theme-assets/vendors/animate/animate.min.css";
import "./app/theme-assets/vendors/flipclock/flipclock.css";
import "./app/theme-assets/vendors/swiper/css/swiper.min.css";
import "./app/theme-assets/css/template-counter.min.css";
import "./app/theme-assets/css/custom.css";

//JS
// import "./app/theme-assets/vendors/vendors.min.js"
// import "./app/theme-assets/vendors/popper.min"
// import  "./app/theme-assets/vendors/swiper/js/swiper.min.js"
// import  "./app/theme-assets/vendors/waypoints/jquery.waypoints.min.js"
// import "./app/theme-assets/vendors/vendors.min.js"
// import  "./app/theme-assets/js/theme.js"
// import  "./app/theme-assets/js/sales-notification.js"
import "popper.js";
import "./app/theme-assets/js/theme_my";
import "bootstrap/dist/js/bootstrap.min"
// import "vendors"



ReactDOM.render(
  <React.StrictMode>

      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"/>
      <App />

  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
