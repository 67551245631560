import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

import Home from "./app/containers/home/home";










function App() {
  return (
    <div>

        <Router>
            <Switch>
                <Route exact path='/' component={Home}/>
            </Switch>
        </Router>
        <script src={"./app/theme-assets/vendors/vendors.min.js"}/>
        <script src={"./app/theme-assets/js/theme.js"}/>
        <script src={"./app/theme-assets/js/sales-notification.js"}/>
    </div>
  );
}

export default App;
