import {React , useEffect} from 'react'
import ReactDOM from "react-dom";
import {Link, NavLink} from 'react-router-dom'
import bitcointImg from "../../../theme-assets/images/icon-bitcoin.png"
import icontEth from "../../../theme-assets/images/icon-eth.png"
import iconVisa from "../../../theme-assets/images/icon-visa.png"
import iconMaestro from "../../../theme-assets/images/icon-maestro.png"
import logoBig from "../../../theme-assets/images/logo-big.png"


function Section1() {

    return (
        <>
            <div className="content-wrapper">
                <div className="content-body">
                    <main>
                        <section className="head-area" id="head-area" data-midnight="white">
                            <div className="bg-banner"></div>
                            <div className="head-content container-fluid d-flex align-items-center">
                                <div className="container">
                                    <div className="banner-wrapper">
                                        <div className="row align-items-center">
                                            <div className="col-lg-8 col-md-12">
                                                <div className="banner-content">
                                                    <h1 className="animated" data-animation="fadeInUpShorter"
                                                        data-animation-delay="1.5s">Join the future of algorithmic<br/>crypto
                                                        trading strategies</h1>
                                                    <h3 className="pt-5 animated" data-animation="fadeInUpShorter"
                                                        data-animation-delay="1.7s">$150B Target cap <strong>$99B
                                                        Raised</strong></h3>
                                                    <div className="row animated" data-animation="fadeInUpShorter"
                                                         data-animation-delay="1.9s">
                                                        <div className="col-lg-9 col-md-12 mr-auto">
                                                            <div className="loading-bar my-3 position-relative">
                                                                <div className="progres-area py-5">
                                                                    <ul className="progress-top">
                                                                        <li></li>
                                                                        <li className="pre-sale">Pre-Sale</li>
                                                                        <li>Soft Cap</li>
                                                                        <li className="bonus">Bonus</li>
                                                                        <li></li>
                                                                    </ul>
                                                                    <ul className="progress-bars">
                                                                        <li></li>
                                                                        <li>|</li>
                                                                        <li>|</li>
                                                                        <li>|</li>
                                                                        <li></li>
                                                                    </ul>
                                                                    <div className="progress">
                                                                        <div
                                                                            className="progress-bar progress-bar-custom"
                                                                            role="progressbar" style={{width: 65 + "%"}}
                                                                            aria-valuenow="100" aria-valuemin="0"
                                                                            aria-valuemax="100"></div>
                                                                    </div>
                                                                    <div className="progress-bottom">
                                                                        <div className="progress-info">65% target
                                                                            raised
                                                                        </div>
                                                                        <div className="progress-info">1 ETH = $1000 =
                                                                            3177.38 CIC
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/*<div className="clock-counter animated"*/}
                                                    {/*     data-animation="fadeInUpShorter" data-animation-delay="2.1s">*/}
                                                    {/*    <div className="message"></div>*/}
                                                    {/*</div>*/}
                                                    <div className="animated" data-animation="fadeInUpShorter"
                                                         data-animation-delay="2.3s">
                                                        <div className="purchase-token-btn">
                                                            <Link  to="#"
                                                               className="btn btn-lg btn-gradient-orange btn-round btn-glow">Purchase
                                                                Token</Link>
                                                        </div>
                                                        <ul className="crypto-links list-unstyled pl-3">
                                                            <li><Link  to="#" title="bitcoin"><img
                                                                src={bitcointImg} alt="bitcointImg"/></Link>
                                                            </li>
                                                            <li><Link  to="#" title="ether"><img
                                                                src={icontEth} alt="icontEth"/></Link></li>
                                                            <li><Link  to="#" title="visa"><img
                                                                src={iconVisa} alt="iconVisa"/></Link></li>
                                                            <li><Link  to="#" title="master"><img
                                                                src={iconMaestro} alt="iconMaestro"/></Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-12 move-first animated"
                                                 data-animation="zoomIn" data-animation-delay="2s">
                                                <div className="logo-wrapper ml-5 pl-5 align-items-center">
                                                    <div className="crypto-logo">
                                                        <div id="ripple"></div>
                                                        <div id="ripple2"></div>
                                                        <div id="ripple3"></div>
                                                        <img src={logoBig}
                                                             className="crypto-logo-img rounded mx-auto d-block pulse2"
                                                             alt="CICO"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-ripple-animation d-none d-md-block">
                                <div className="left-bottom-ripples">
                                    <div className="ripples"></div>
                                </div>
                                <div className="top-right-ripples">
                                    <div className="ripples"></div>
                                </div>
                            </div>
                        </section>
                    </main>
                </div>
            </div>
        </>
    )

}


export default Section1
