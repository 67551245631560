import {React , useEffect} from 'react'
import {Link, NavLink} from 'react-router-dom'
import Header from "../../component/header/header";
import VerticalSocial from "../../component/verticalSocial/verticalSocial";
import Footer from "../../component/footer/footer";
import Section1 from "./sections/section1";
import Preloader from "../../component/preloader/preloader";
import ExchangeListing from "../../component/exchangeListing/exchangeListing";
import AboutSection from "./sections/aboutSection";
import PropblemsSolutions from "./sections/propblemsSolutions";
import WhitePaper from "./sections/whitePaper";
import TokenChart from "./sections/TokenChart";
import {MiddayHeader, MiddaySection} from 'react-midday';


function Home() {

    return (
        <>
            <Preloader/>
            <VerticalSocial/>
            <Header/>
            <MiddaySection name="white">
                <Section1/>
            </MiddaySection>
            <MiddaySection name="default">
                <ExchangeListing/>
                <AboutSection/>
                <PropblemsSolutions/>
                <WhitePaper/>
                <TokenChart/>
            </MiddaySection>
            <MiddaySection name="white">
                <Footer/>
            </MiddaySection>
        </>
    )

}


export default Home
