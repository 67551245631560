import React from 'react'
import {Link, NavLink} from 'react-router-dom'
import {MiddayHeader, MiddaySection} from 'react-midday';


function VerticalSocial() {
    return (
    <MiddayHeader className="fixed vertical-social">
        <div className="midnightHeader middayHeader  default">
            <div className="midnightInner">
                <ul>
                    <li><Link to="#"><i className="fa fa-telegram" aria-hidden="true"></i></Link></li>
                    <li><Link to="#"><i className="fa fa-medium" aria-hidden="true"></i></Link></li>
                    <li><Link to="#"> <i className="fa fa-twitter" aria-hidden="true"></i></Link></li>
                    <li><Link to="#"><i className="fa fa-github" aria-hidden="true"></i></Link></li>
                </ul>
            </div>
        </div>
        <div className="midnightHeader middayHeader  white">
            <div className="midnightInner">
                <ul>
                    <li><Link to="#"><i className="fa fa-telegram" aria-hidden="true"></i></Link></li>
                    <li><Link to="#"><i className="fa fa-medium" aria-hidden="true"></i></Link></li>
                    <li><Link to="#"> <i className="fa fa-twitter" aria-hidden="true"></i></Link></li>
                    <li><Link to="#"><i className="fa fa-github" aria-hidden="true"></i></Link></li>
                </ul>
            </div>
        </div>
    </MiddayHeader>
    )

}


export default VerticalSocial
