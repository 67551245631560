import React, {useState} from 'react'
import {Link, NavLink} from 'react-router-dom'
import logo from "../../theme-assets/images/logo.png"


function Header() {

    return (
        <header className="page-header">
            <nav className="main-menu static-top navbar-dark navbar navbar-expand-lg fixed-top mb-1"><div className="container">
                <Link className="navbar-brand animated" data-animation="fadeInDown" data-animation-delay="1s" to="index.html"><img src={logo} alt="Crypto Logo"/><span className="brand-text"><span className="font-weight-bold">Crypto</span> ICO</span></Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div id="navigation" className="navbar-nav ml-auto">
                        <ul className="navbar-nav mt-1">
                            <li className="nav-item animated" data-animation="fadeInDown" data-animation-delay="1.1s">
                                <Link  className="nav-link" to="index.html#about">What is ICO</Link>
                            </li>
                            <li className="nav-item animated" data-animation="fadeInDown" data-animation-delay="1.2s">
                                <Link  className="nav-link" to="index.html#problem-solution">Solutions</Link>
                            </li>
                            <li className="nav-item animated" data-animation="fadeInDown" data-animation-delay="1.3s">
                                <Link  className="nav-link" to="index.html#whitepaper">Whitepaper</Link>
                            </li>
                            <li className="nav-item animated" data-animation="fadeInDown" data-animation-delay="1.4s">
                                <Link  className="nav-link" to="index.html#roadmap">Roadmap</Link>
                            </li>
                            <li className="nav-item animated" data-animation="fadeInDown" data-animation-delay="1.5s">
                                <Link  className="nav-link" to="index.html#team">Team</Link>
                            </li>
                            <li className="dropdown show mr-2 px-2 animated" data-animation="fadeInDown" data-animation-delay="1.6s">
                                <Link  className="dropdown-toggle white" to="#" role="button" id="more" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">More</Link>
                                <div className="dropdown-menu" aria-labelledby="more">
                                    <Link  className="dropdown-item" to="index.html#faq">FAQ</Link>
                                    <Link  className="dropdown-item" to="index.html#blog">Blog</Link>
                                    <Link  className="dropdown-item" to="index.html#contact">Contact</Link>
                                    <Link  className="dropdown-item link" to="template-404.html">404</Link>
                                    <Link  className="dropdown-item link" to="template-inner-page-with-sidebar.html">Sample Page</Link>
                                </div>
                            </li>
                            <li className="dropdown show mr-4 animated" data-animation="fadeInDown" data-animation-delay="1.7s">
                                <Link  className="dropdown-toggle white" to="#" role="button" id="language" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span className="flag-icon flag-icon-us"></span> EN</Link>
                                <div className="dropdown-menu" aria-labelledby="language">
                                    <Link  className="dropdown-item" to="#"><span className="flag-icon flag-icon-de"></span> GN</Link>
                                    <Link  className="dropdown-item" to="#"><span className="flag-icon flag-icon-es"></span> SP</Link>
                                    <Link  className="dropdown-item" to="#"><span className="flag-icon flag-icon-mq"></span> FR</Link>
                                </div>
                            </li>
                        </ul>
                        <span id="slide-line"></span>
                        <div className="form-inline mt-2 mt-md-0">
                            <Link  className="btn btn-sm btn-gradient-orange btn-round btn-glow my-2 my-sm-0 animated" data-animation="fadeInDown" data-animation-delay="1.8s" to="https://pixinvent.com/demo/crypto-ico-admin/html/ltr/vertical-menu/account-login.html" target="_blank">Sign in</Link>
                        </div>
                    </div>
                </div>
            </div>
            </nav>
        </header>
        )

}


export default Header
